.no-padding {
	padding: 0;
	margin: 0;
}

.flex-center{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

body {
	text-align: center;
}

main{
	&>div{
		padding-bottom: 5vh;
	}
	h1, h2, h3, h4{
		padding: 5vh 0;
		margin: 0;
	}
	p{
		padding-bottom: 5vh;
		margin: 0;
	}
}

.img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.btn{
	margin: 0 3vh;
}

.btn-primary:hover{
	color: $primary;
	background-color: transparent;
}

.btn-secondary:hover{
	color: $secondary;
	background-color: transparent;
}

a{
	&,*{
		transition: all 0.5s ease;
		&:hover{
			transform: scale(1.05);
		}
	}
}

.carre{
	position: relative;
	&:before{
		content: "";
		display: block;
		padding-top: 100%;
	}
	&>*{
		position:  absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.typo{
	font-family: 'Press Start 2P', cursive;
	color: $rouge;
	vertical-align: bottom;
}

.div-link{
	cursor: pointer;
}

p {
	font-size: 2rem;
}

@media ($md) {
	h1{
		font-size: 2em;
	}

	h2{
		font-size: 1.6em;
	}

	h3{
		font-size: 1.4em;
	}

	h4{
		font-size: 1.2em;
	}

	p{
		font-size: 1em;
	}
}
