header{
	.logo{
		height: 20vh;
		img{
			object-fit: cover;
			height: 100%;
		}
	}
	&>div{
		background-color: rgba($secondary, 0.35);
		width: 100%;
		height: 100%;
		padding: 2vw;
		z-index: 1;
	}
	&.default{
		.logo{
			height: 15vh;
		}
		&>div{
			background-color: rgba($secondary, 0.6);
		}
	}
}

.navbar{
	background-color: $beige;
	box-shadow: 0 2px 2px 0px rgba($secondary, 0.2);
	.navbar-brand{
		display: none;
		img{
			height: 100px;
		}
	}
	.navbar-nav{
		width: 100%;
		justify-content: space-around;
		.btn{
			margin: 0;
		}
		.mon-dropdown{
			.dropdown-link{
				display: flex;
				justify-content: center;
				align-items: center;
				.nav-link{
					padding-right: .5rem;
					padding-left: .5rem;
					cursor: pointer;
				}
			}
			.dropdown-div{
				display: none;
				min-width: 10rem;
				margin: .125rem 0 0;
				background-color: $primary;
				background-clip: padding-box;
				box-shadow: 0 2px 2px 0px rgba($secondary, 0.2);
				z-index: 100;
				&>a{
					padding: 0.25rem 1.5rem;
					&.active{
						background-color: $gris;
						color: $primary;
					}
				}
			}
		}
	}
	.navbar-toggler{
		border: none;
		&:focus, &active{
			outline: none;
			box-shadow: none;
		}
	}
}
.burger{
	display: block;
	cursor: pointer;
	&, &::before, &::after{
		width: 24px;
		height: 4px;
		background: $secondary;
		transition: all 0.3s;
		border-radius: 5px;
	}
	&::before, &::after{
		content: "";
		display: block;
		position: absolute;
	}
	&::before{
		margin-top: -8px;
	}
	&::after{
		margin-top: 8px;
	}
	&.active{
		background: none;
	}
	&.active::after{
		margin-top: 0px;
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	&.active::before{
		margin-top: 0px;
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.wrapper{
	overflow: hidden;
	position: relative;
	z-index: 1;
	color: $secondary;
	background: url('/images/pattern.svg');
	background-color: $primary;
	background-size: 20%, auto, contain;
}

.call-to-action{
	color: $primary;
	padding: 0 5vh 5vh 5vh;
	&>div{
		background-color: $secondary;
		padding: 5vh;
	}
}

footer{
	left: 0;
	bottom: 0;
	z-index: -1;
	width: 100%;
	position: fixed;
	&>.flex-center{
		padding: 2vh 0;
		width: fit-content;
		margin: auto;
		a{
			color: $beige;
			margin: 0;
		}
		.ligne{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1vh;
			i{
				padding-right: 1vw;
				color: $beige;
			}
			img{
				height: 4em;
			}
		}
	}
	canvas{
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
	}
}

@media ($md) {
	.navbar{
		.navbar-brand{
			display: inline-block;
		}
	}
}

@media (min-width: 992px){
	.mon-dropdown{
		.flex-center{
			align-items: flex-start;
		}
		.dropdown-div {
			position: absolute;
			top: 95%;
		}
	}
}

@media ($md) {
	header{
		&.landing{
			&>div{
				height: 100%;
				width: 100%;
			}
		}
	}
	.navbar{
		.btn-group{
			.nav-link{
				padding-right: 0.5rem;
				padding-left: 0.5rem;
			}
		}
	}
}
